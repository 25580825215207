import ConfirmationModal from "../../ConfirmationModal";

import OptionDirection from "./OptionDirection";
import TableEditor from "./TableEditor";
import TableEditorAlt from "./TableEditorAlt";
import { useParams } from "react-router-dom";
import { getNewLoad } from '../../apiCalls';

import {
  CommodityCodeInfoTooltip,
  GrossMassInfoTooltip,
  NetMassInfoTooltip,
  PackageTypeInfoTooltip,
  PreferentialTooltip,
} from "./Tooltips";

import Spreadsheet, {useSpreadsheetHandle} from "../../Spreadsheet/Spreadsheet";
import React, { useState, useContext, useEffect, useMemo, useCallback, useReducer} from "react";
import { StoreContext } from "../../Store";
import { excelReducer, productReducer } from "./reducers";
import { getEidr, postNewLoad } from "../../apiCalls";

import {Popover, PopoverContent, PopoverTitle, Modal, Button, Tabs, Tab, Alert, Container, Col, Row } from "react-bootstrap";
import { Input } from "../../Input/Input";
import Toast from "../../Toast/Toast";
import hscode_img from "../../../images/hscode-example.png";
import switchSVG from "../../../images/switch.svg";
import { useLocation } from "react-router";
import { PreferentialModal } from "./Tooltips";
import { useSecurity } from "../../Security/security";
import GridTotalsPI from "./GridTotalsPI";
import BulkOperations from "../../EpoListLoads/Details/Tabs/BulkOperations";
import { createPromiseCapability } from "pdfjs-dist";
import SaveDraftWithErrorsModal from "../SaveDraftWithErrorsModal";
import { isAdjustmentsRoute } from "../LoadInfo";

const InfoModal = ({ showModal, setShowModal, tabKey, setKey }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header className="floating-box-header-newload">
        Additional Information
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="controlled-tab-example"
          className="mb-2"
          activeKey={tabKey}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="commoditycode" title="HS Code" tabClassName="tab-link">
            <p className="">
            </p>

            <img src={hscode_img} width="100%" alt="" />
          </Tab>
          <Tab eventKey="origin" title="Origin" tabClassName="tab-link">
            <p className="">
            </p>
          </Tab>
          <Tab
            eventKey="healthCert"
            title="Health Certificate"
            tabClassName="tab-link"
          >
            <p className="">
            </p>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer
        className="w-100 justify-content-end"
        style={{ flexDirection: "row" }}
      >
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ExcelGridContext = React.createContext();
export const ProductGridContext = React.createContext();



const ProductInfo = ({ visible, nextPage, lastPage, submit, resetTrigger, setResetDone, prevPage, loadStatus = null }) => {
  const store = useContext(StoreContext);
  const location = useLocation();
  const { validateRole } = useSecurity();
  const spreadsheetHandle = useSpreadsheetHandle();
  const { id } = useParams();

  const [productState, productDispatch] = useReducer(productReducer, {
    rows: [],
    config: [],
    emptyRow: {},
    onUpdate: null
  });

  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [RgrWarningShown, setRgrWarningShown] = useState(false);
  const [prefWarningShown, setPrefWarningShown] = useState(false);
  const [WarningData, setWarningData] = useState([]);
  const [hasValidatedRows, setHasValidatedRows] = useState(false);
  const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
  const [disableOrderForm, setDisableOrderForm] = useState(false);
  const [onConfirmFunctionDraftModal, setOnConfirmFunctionDraftModal] = useState(null);

  const [showPreferentialConfirmation, setShowPreferentialConfirmation] =
    useState(false);
  const [key, setKey] = useState("hscode");

  useEffect(() => {
    async function fetchData() {
      getNewLoad("currency").then((data) => { store.currencyOptions[1](data); });
      getNewLoad("vat").then((data) => {store.VatOptions[1](data)});
      getNewLoad("pref").then((data) => {store.PreferentialOptions[1](data)})
    }
    fetchData();
  }, [])

  // const openAtKey = (newKey) => {
  //   setShowModal(true);
  //   setKey(newKey);
  // };

  const isFromFile = store.newLoadFromFile[0];
  const fileData = store.newLoadFileData[0];

  useEffect(() => {
    if (isFromFile && fileData?.Items && store.VatOptions[0] && store.PreferentialOptions[0]) {
      store.pasteNewLoad[1](true);
      const rows = fileData.Items.map((obj) => [
        { text: nullToBlank(obj.commodityCode) },
        { id: obj?.id, text: nullToBlank(obj.origin) },
        { text: nullToBlank(obj.description) },
        { text: nullToBlank(Math.floor(obj.noOfPkgs)) },
        { text: nullToBlank(obj.typeOfPkgs) },
        { text: nullToBlank(obj.grossKG) },
        { text: nullToBlank(obj.netKG) },
        { text: nullToBlank(obj.loadValue) },
        { text: nullToBlank(obj.quantity) },
        { text: nullToBlank(obj.healthCert) },
        { text: nullToBlank(obj.catchCert)},
        { text: nullToBlank(obj.meursing) },
        { text: nullToBlank(obj.organic) },
        { text: store.VatOptions[0].find((opt) => parseInt(opt.id) === parseInt(obj.VAT))?.data},
        { text: nullToBlank(obj.RGR) },
        { text: store.PreferentialOptions[0].find((opt) => opt.id === obj.preferential)?.data}
      ]);

      spreadsheetHandle.current.excelDispatch({ type: "set", detail: rows });
    }
  }, [isFromFile, fileData, spreadsheetHandle.current.excelDispatch, store.VatOptions[0], store.PreferentialOptions[0]]);

  useEffect(() => {
    if (store.VatOptions[0] && store.PreferentialOptions[0]) {
    const fetchEIDR = async (id) => {
      store.pasteNewLoad[1](true);
      const returnData = await getEidr(id);
      const rows = returnData.Items.map((obj) => [
        { text: nullToBlank(obj.commodityCode) },
        { id: obj.id, text: nullToBlank(obj.origin) },
        { text: nullToBlank(obj.description) },
        { text: nullToBlank(obj.noOfPkgs) },
        { text: nullToBlank(obj.typeOfPkgs) },
        { text: nullToBlank(obj.grossKG) },
        { text: nullToBlank(obj.netKG) },
        { text: nullToBlank(obj.loadValue) },
        { text: nullToBlank(obj.quantity) },
        { text: nullToBlank(obj.healthCert) },
        { text: nullToBlank(obj.catchCert)},
        { text: nullToBlank(obj.meursing) },
        { text: nullToBlank(obj.organic) },
        { text: store.VatOptions[0].find((opt) => parseInt(opt.id) === parseInt(obj.VAT))?.data},
        { text: nullToBlank(obj.RGR) },
        { text: store.PreferentialOptions[0].find((opt) => opt.id === obj.preferential)?.data},
        ]
    );

    spreadsheetHandle.current.excelDispatch({ type: "set", detail: rows });
    };
      if (id) {
      fetchEIDR(id);
    }
  }
  }, [location, spreadsheetHandle.current.excelDispatch, , store.VatOptions[0], store.PreferentialOptions[0]]);

  const [errorMsg, setErrorMsg] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const [isAlertOpen, setAlertOpen] = useState(false);

  const [tableHasErrors, setTableHasErrors] = useState(false);

  const handleTableClicked = useCallback(() => {
    if (isAlertOpen) {
      setAlertOpen(false);
    }
  }, [isAlertOpen]);

  useEffect(() => { //Class product-table-cell does not seem to be used. Obsolete?
    if (
      tableHasErrors &&
      document.querySelectorAll(".product-table-cell.error").length === 0
    ) {
      setTableHasErrors(false);
    }
  }, [spreadsheetHandle.current.excelState.rows, tableHasErrors]);

  const checkControlledGoods = () => {
    //check if store.controlledGoods[0] is 'N' or 'Y' or 'n' or 'y'. If that's the case, return true, otherwise return false
    if (store.controlledGoods[0] === 'N' || store.controlledGoods[0] === 'Y' || store.controlledGoods[0] === 'n' || store.controlledGoods[0] === 'y') return true;
    else return null;
  }

  const checkLoadInformation = () => {
    const requiredData = [
      store.directionSelected[0],
      store.selectFcl[0],
      store.despatch[0],
      store.destination[0],
      !store.RowExport[0] ? store.entry[0] : true,
      ((store.GBimport[0] || store.GBexport[0]) && (store.importMixedPortsModal[0] === 1 || store.exportMixedPortsModal[0] === 1)) ? store.portsModal[0]: true,
      store.consignor[0],
      store.consignee[0],
      store.shipStatus[0],
      store.truck[0],
      store.date[0],
      store.jobRef[0],
      store.invoicenumber[0],
      store.invoicecurr[0], 
      store.selectincoterm[0],

      store.ffd[0] == false && !store.RowImport[0] ? store.exit[0] : true, //if rowImport is true - which means it's coming from ROW, we don't care about the exit port. If RowImport is false, that means the load is coming from ROI or EU, so we need to check the exit port
      store.ffd[0] == false && store.GBimport[0] ? checkControlledGoods(): true,  //if we are inporting to GB, then GBimport is true, then we need to check the controlled goods. If it is false, we don't need to check the controlled goods

      store.directionSelected[0] == 1 ? store.exit2[0] : true,
      store.directionSelected[0] == 1 ? store.entry2[0] : true,
      store.selectincoterm[0] == '7' && store.GBimport[0] ? store.incotermLocation[0] : true //If DDP is set as incoterm and GBimport is true, we need an incotermLocation value
    ]
    
    const isEmpty = (data) => data === null || data === undefined || data === '' || data === 0;


    if (requiredData.some(isEmpty)) {
      setToastTitle("Missing data on Load Information");
      setErrorMsg("Please check Load Information - There is missing information on the Load information screen including any required Adjustments");
      setAlertOpen(true);
      return false;
    }

    const isAdjstmentRequired = isAdjustmentsRoute(store.directionSelected[0]) && ['1', '2', '11', '12'].includes(store.selectincoterm[0]?.toString());
    if (isAdjstmentRequired){
      const adjValues = store.adjustments[0];

      /*do a loop through adjValues, and for each element, check if required is true, if is true, check if amount is empty or 0, then check if currency is empty or 0, if any of these conditions are met, return false, else return true*/
      for (let adjustment of adjValues){
        if (adjustment.required){
          if (adjustment.amount === '' || adjustment.amount === 0 || adjustment.currency === '' || adjustment.currency === 0){
            setToastTitle("Missing data on Load Information");
            setErrorMsg("Please check Load Information - There is missing information on the Load information screen including any required Adjustments");
            setAlertOpen(true);
            return false;
          }
        }
      }
    }
    return true;

  }






   const validateProductRows = async (productRows, config, set) => {
    const collapsedProducts = productRows
      .filter((product) =>
        Object.values(product).some((cell) => cell && cell.text !== "")
      )
      .map((row) => {
        const obj = {};
        config.forEach((col, idx) => {
          const { key, default_text } = col;
          obj[key] = {
            ...row[key],
            text: (row[key]?.text || default_text) ?? "",
          };
        });
        return obj;
      });

    if (collapsedProducts.length === 0) {
      setToastTitle("No Products Entered");
      setErrorMsg(
        "No products have been entered, please enter at least 1 product line to continue."
      );
      setAlertOpen(true);
      return;
    }

    set(collapsedProducts);

    const items = collapsedProducts.map((product) => ({
      commoditycode: product?.commoditycode?.text ?? null,
      origin: product.origin.text,
      description: product.description.text,
      packageCount: product.packageCount.text,
      packageType: product.packageType.text,
      grossMass: product.grossMass.text,
      netMass: product.netMass.text,
      itemValue: product.itemValue.text,
      quantity2: product.quantity2.text,
      healthCert: product.healthCert.text,
      meursing: product.meursing.text,
      organic: product.organic.text,
      RGR: product.RGR.text,
      VAT: product.VAT.text,
      preferential: product.preferential.text,
      catchCert: product.catchCert?.text,
      movementIdSelected: store.directionSelected[0],
      despatchIdSelected: store.despatch[0]
    }));

    const filteredList = removeEmptyOrNull(items).filter(
      (value) => Object.keys(value).length !== 0
    );

    try {

      setTableHasErrors(false);
      const data = await postNewLoad(filteredList, "validation");
      if (data[0].error === true) {
        setProductErrors(
          data,
          config.map((col) => col.key),
          set
        );
      } else {
        clearErrors(set);
        store.validatedRowsReadyToSubmit[1](filteredList);
        gotoNextPage(filteredList);
      }
    } catch (err) {
      console.error(err);
      setToastTitle("Please check your products");
      setErrorMsg(
        "Sorry, something went wrong whilst checking your products, please review any cells highlighted in red."
      );
      setAlertOpen(true);
    }
  };

  const clearErrors = useCallback((set) => {
    set((prevRows) => {
      for (let y = 0; y < prevRows.length; y++) {
        prevRows[y].commoditycode = {
          ...prevRows[y].commoditycode,
          error: undefined,
        };
        prevRows[y].origin = {
          ...prevRows[y].origin,
          error: undefined,
        };
        prevRows[y].description = {
          ...prevRows[y].description,
          error: undefined,
        };
        prevRows[y].packageCount = {
          ...prevRows[y].packageCount,
          error: undefined,
        };
        prevRows[y].packageType = {
          ...prevRows[y].packageType,
          error: undefined,
        };
        prevRows[y].grossMass = {
          ...prevRows[y].grossMass,
          error: undefined,
        };
        prevRows[y].netMass = {
          ...prevRows[y].netMass,
          error: undefined,
        };
        prevRows[y].itemValue = {
          ...prevRows[y].itemValue,
          error: undefined,
        };
        prevRows[y].quantity2 = {
          ...prevRows[y].quantity2,
          error: undefined,
        };
        prevRows[y].healthCert = {
          ...prevRows[y].healthCert,
          error: undefined,
        };
        prevRows[y].catchCert = {
          ...prevRows[y].catchCert,
          error: undefined,
        };
        prevRows[y].meursing = {
          ...prevRows[y].meursing,
          error: undefined,
        };
        prevRows[y].organic = {
          ...prevRows[y].organic,
          error: undefined,
        };
        prevRows[y].VAT = {
          ...prevRows[y].VAT,
          error: undefined
        }
        prevRows[y].preferential = {
          ...prevRows[y].preferential,
          error: undefined,
        };
        prevRows[y].RGR = {
          ...prevRows[y].RGR,
          warn: undefined,
        };
      }
      return [...prevRows];
    });
  }, []);

  /**
   * Function unchanged despite reducer introduction, as it still works - if more complicated
   * rows etc required in the future then this will need to be amended.
   */
  const setProductErrors = useCallback((errors, keys, set) => {
    set((prevRows) => {
      for (let y = 0; y < prevRows.length; y++) {
        prevRows[y].commoditycode = {
          ...prevRows[y].commoditycode,
          error: errors[y].commcodeError,
        };
        prevRows[y].origin = {
          ...prevRows[y].origin,
          error: errors[y].originError,
        };
        prevRows[y].description = {
          ...prevRows[y].description,
          error: errors[y].descriptionOfGoodsError,
        };
        prevRows[y].packageCount = {
          ...prevRows[y].packageCount,
          error: errors[y].packageCountError,
        };
        prevRows[y].packageType = {
          ...prevRows[y].packageType,
          error: errors[y].packagetypeError,
        };
        prevRows[y].grossMass = {
          ...prevRows[y].grossMass,
          error: errors[y].grossMassError,
        };
        prevRows[y].netMass = {
          ...prevRows[y].netMass,
          error: errors[y].netMassError,
        };
        prevRows[y].itemValue = {
          ...prevRows[y].itemValue,
          error: errors[y].itemValueError,
        };
        prevRows[y].quantity2 = {
          ...prevRows[y].quantity2,
          error: errors[y].quantity2Error,
        };
        prevRows[y].healthCert = {
          ...prevRows[y].healthCert,
          error: errors[y].healthCertError,
        };
        prevRows[y].catchCert = {
          ...prevRows[y].catchCert,
          error: errors[y].catchCertError,
        };
        prevRows[y].meursing = {
          ...prevRows[y].meursing,
          error: errors[y].meursingError,
        };
        prevRows[y].organic = {
          ...prevRows[y].organic,
          error: errors[y].organicError,
        };
        prevRows[y].VAT = {
          ...prevRows[y].VAT,
          error: errors[y].VATError,
        }
        prevRows[y].preferential = {
          ...prevRows[y].preferential,
          error: errors[y].preferentialError,
        };
        prevRows[y].RGR = {
          ...prevRows[y].RGR,
          warn: errors[y].RGRWarn,
        };
      }
      return [...prevRows];
    });
    setTableHasErrors(true);
    setToastTitle("Please check your products");
    setErrorMsg(
      "Sorry, something went wrong whilst checking your products, please review any cells highlighted in red."
    );
    setAlertOpen(true);
  }, []);


useEffect(() => {
  if (hasValidatedRows && typeof submit === 'function') {
   submit(new Event('submit'));
   resetPage();
  }
}, [hasValidatedRows]);


  const validateHsRows = async (isDraft) => {
    //set disableOrderForm to true to disable the order form button temporarily
    setDisableOrderForm(true);
    // Collapses any completely empty rows and squeezes non-empty rows together.
    const collapsedFiles = [];
    let collapsedRows = [];
    for (const idx in spreadsheetHandle.current.excelState.rows) {
      const row = spreadsheetHandle.current.excelState.rows[idx];
      if (row.some((cell) => cell.text !== "")) {
        collapsedRows.push(row);
        collapsedFiles.push(spreadsheetHandle.current.excelState.files[idx]);
      }
    }

    // Sets default values for any columns that require it.
    collapsedRows = collapsedRows.map((row) => {
        const new_row = [];
        spreadsheetHandle.current.excelState.config.forEach((col, idx) => {
          const { default_text } = col;
          new_row.push({
            ...row[idx],
            text: (row[idx]?.text || default_text) ?? "",
          });
        });
        return new_row;
      });

    if (collapsedRows.length === 0) {
      setToastTitle("No Products Entered");
      setErrorMsg(
        "No products have been entered, please enter at least 1 product line to continue."
      );
      setAlertOpen(true);
      setDisableOrderForm(false);
      return;
    }


    spreadsheetHandle.current.excelDispatch({ type: "set", detail: collapsedRows }) // update the visual rows to the newly collapsed - needed so error index = shown index.
    spreadsheetHandle.current.excelDispatch({ type: "set-files", detail: collapsedFiles });
    // Uses configuration 'keys' to construct array of item objects.
    const items = collapsedRows.map((row, row_idx) => {
      const obj = {};
      spreadsheetHandle.current.excelState.config.forEach((col, idx) => {
        const { key } = col;
        obj[key] = row[idx]?.text ?? "";
        if (row[idx]?.id !== undefined) {
          obj["id"] = row[idx].id;  
        }
      });

      // -- TODO: if dummy value is provided by backend, remove the below.
      if (!obj["commoditycode"] && store.tadEns[0]) {
        obj["commoditycode"] = null;
      }
      // --

      obj.file_ids = (collapsedFiles[row_idx] ?? []).map(file => file.id);
      return obj;
    });

    // Backend doesn't like empty strings or nulls, prefered straight 'undefined'.
    const itemsWithoutMovement = removeEmptyOrNull(items).filter(
      (value) => Object.keys(value).length !== 0
    );

    const filteredList = itemsWithoutMovement.map(x => ({
      ...x,
      movementIdSelected: store.directionSelected[0],
      despatchIdSelected: store.despatch[0]
  }))

    try {

    if (!isDraft) {
      store.isEidr[1](0);
      setTableHasErrors(false);
      const data = await postNewLoad(filteredList, "validation");
      if (data[0].error === true) {
          setDisableOrderForm(false);
          const errorData = data.map(d => ({ ...d, RGRWarn: null, preferentialWarn: null }));
          setHsRowErrors(errorData);     
          setWarningData(data); 
      } else if(([data[0].RGRvalid , data[0].areaMatchValid].includes(false)) && !RgrWarningShown && data[0].error === false){
          setDisableOrderForm(false);
          setHsRowErrors(data); 
          setWarningData(data)
          setRgrWarningShown(true); 
          setAlertOpen(false);     
      } 
      else {        
        const newWarnData = WarningData?.map(x => ({
            ...x,
            RGRWarn: undefined,
            preferentialWarn: undefined
        }))
        if(newWarnData.length>0){
          setHsRowErrors(newWarnData);
        }


        store.validatedRowsReadyToSubmit[1](filteredList);
        setAlertOpen(false);
        setHsRowErrors(data); 
        setWarningData([]);
        setDisableOrderForm(false);
        if(checkLoadInformation()){
          gotoNextPage(filteredList);
        }

        // gotoNextPage(filteredList);
      } 
    } else { 
      setTableHasErrors(false);
      const data = await postNewLoad(filteredList, "validation");
      if (data[0].error === true) {
          const errorData = data.map(d => ({ ...d, RGRWarn: null, preferentialWarn: null }));
          setHsRowErrors(errorData, false);     
          setWarningData(data);
          handleOnConfirmDraftModal(filteredList);
          setDisableOrderForm(false);
      } else if(([data[0].RGRvalid , data[0].areaMatchValid].includes(false)) && !RgrWarningShown && data[0].error === false){
          setHsRowErrors(data, false); 
          setWarningData(data)
          setRgrWarningShown(true); 
          setAlertOpen(false);
          handleOnConfirmDraftModal(filteredList);
          setDisableOrderForm(false);
      }
      else {
          store.validatedRowsReadyToSubmit[1](filteredList);
          setHasValidatedRows(true)
          setAlertOpen(false);
          lastPage(filteredList);
          setDisableOrderForm(false);
      } 
    }
    } catch (err) {
      console.error(err);
      setToastTitle("Please check your products");
      setErrorMsg(
        "Sorry, something went wrong whilst checking your products, please review any cells highlighted in red."
      );
      setAlertOpen(true);
      setDisableOrderForm(false);
    }
  };
 
  /* Warning - not a pretty function. */  
  const setHsRowErrors = useCallback((errors, showToast=true) => {
    const errorsPresent = errors[0].error === true; //check if the key error is present and equals true
    const keys = spreadsheetHandle.current.excelState.config.map((c) => c.key);
    const updateFunc = ((prevRows) => {
      for (let y = 0; y < prevRows.length; y++) {
        const row = keys.map((key, idx) => {
          // TODO: remove .toLowerCase() comparison and replace with simple errors[y][key] lookup once backend changed.
          if (idx === 0){
            if (y < errors.length) {
              prevRows[y][idx].itemId = errors[y].itemId
            }
          }
          if (y < errors.length) {
          return {
            ...prevRows[y][idx],
            error: errorsPresent && Object.entries(errors[y]).find(
              ([k, v]) => k.toLowerCase() === `${key.toLowerCase()}error` // if errorsPresent then we set error to the cells that have errors
            )?.[1],
            warn: !errorsPresent && Object.entries(errors[y]).find(
              ([k, v]) => k.toLowerCase() === `${key.toLowerCase()}warn` // if errorsPresent is false, then we set the warnings to the cells that have warnings
            )?.[1],
          };
        }
        });
        // dirty hack to fix the above...
        const commIndex = keys.indexOf("commoditycode");
        if (commIndex !== -1) {
          row[commIndex] = {
            ...prevRows[y][commIndex],
            error: errors[y].commcodeError,
          };
        }
        prevRows[y] = [...row];
      }
      return [...prevRows];
    });
    
    spreadsheetHandle.current.excelDispatch({ type: "set", detail: updateFunc });
    setTableHasErrors(true);
    if(showToast && errorsPresent){
      setToastTitle("Please check your products");
      setErrorMsg("Sorry, something went wrong whilst checking your products, please review any cells highlighted in red.");
      setAlertOpen(true);
    }
  }, [spreadsheetHandle.current.excelState.config]);

  useEffect(() => {
    if (resetTrigger) {
      resetPage();
        setResetDone();
    }
}, [resetTrigger, setResetDone]);

  const resetPage = () => {
    spreadsheetHandle.current.excelDispatch({ type: "reset" });
    productDispatch({ type: "reset" });
    store.pasteNewLoad[1](null);
    setShowConfirmation(false);
    store.resetNewLoad();
    store.isEidr[1](0);
    setHasValidatedRows(false);
    setDisableOrderForm(false);
  };

  const [showPreferentialAlreadyShown, setShowPreferentialAlreadyShown] = useState(false);

  const gotoNextPage = (rows) => {
    setAlertOpen(false);
    if (rows.some((row) => row.preferential === "YES")) {
      if (!showPreferentialAlreadyShown) {
        setShowPreferentialConfirmation(true);
        setShowPreferentialAlreadyShown(true);
      }
      else {
        nextPage();
      }
    } else {
      nextPage();
    }
  };
  const productContext = { productState, productDispatch };
  const [netMass, setNetMass] = useState(0);
  const [grossMass, setGrossMass] = useState(0);
  const [itemsCount, setItemsCount] = useState(0)
  const [totalValue, setTotalValue] = useState(0);
  const [itemRows, setItemRows] = useState(0);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [showBulkBtn, setShowBulkBtn] = useState(false);

  useEffect(() => {
    const sumsFunction = (state) => {
      let headers = [
        {name: "grossMass", update: setGrossMass},
        {name: "netMass", update: setNetMass},
        {name: "itemValue", update: setTotalValue},
        {name: "packageCount", update: setItemsCount}
      ];
      let itemRowsCalc = 0;
      const isArray = Array.isArray(state.rows?.[0])
      headers = headers.map((obj) => { return {...obj, value: 0, col: isArray ? state.config.findIndex((c) => c.key === obj.name) : undefined} });

        state.rows.forEach((row) => {
          headers.forEach((h) => {
            const key = isArray ? h.col : h.name
            h.value += isNaN(parseFloat(row[key]?.text)) ? 0 : parseFloat(row[key]?.text);
          })
          itemRowsCalc += (isArray ? row : Object.values(row)).some((cell) => !!cell.text) ? 1 : 0;
        })

      headers.forEach((h) => {
        h.update(h.value);
      })

      setItemRows(itemRowsCalc);

    }

      spreadsheetHandle.current.excelDispatch({ type: "setOnUpdate", detail: sumsFunction })
      productDispatch({ type: "setOnUpdate", detail: sumsFunction });

  }, [])
  
  useEffect(()=>{
    (itemRows >= 1) ?  setShowBulkBtn(true) : setShowBulkBtn(false)
  },[itemRows])

  useEffect(() => {
    if (visible) {
      if (store.pasteNewLoad[0] === true) {
        spreadsheetHandle.current.excelDispatch({type: 'trigger-update'})
      }
      else if (store.pasteNewLoad[0] === false) {
        productDispatch({type: 'trigger-update'})
      }
    }
  }, [store.pasteNewLoad[0], visible])

  //useEffect to show the save draft modal if a function is present on the onConfirmFunctionDraftModal state
  useEffect(() => {
    if (onConfirmFunctionDraftModal) {
      setShowSaveDraftModal(true);
    }
    else{
      setShowSaveDraftModal(false);
    }
  }, [onConfirmFunctionDraftModal]);

  //function to set the onConfirmFunctionDraftModal state to a function that will open the draft modal and serves as an onConfirm function for the draft modal
  const handleOnConfirmDraftModal = (filteredList) => {
    const onConfirmFunction = () => {
      store.validatedRowsReadyToSubmit[1](filteredList);
      setHasValidatedRows(true)
      setAlertOpen(false);
      lastPage(filteredList);
    }
    setOnConfirmFunctionDraftModal(() => onConfirmFunction);

  }

  return (
    <div onClick={handleTableClicked}>
      <Toast className="error-toast">
        <Alert style={{ maxWidth: "35rem", backgroundColor: "#E4E3ED"}} show={isAlertOpen}>
          <button
            className="btn-close position-absolute top-0 end-0 p-3"
            onClick={() => setAlertOpen(false)}
          />
          <div
            className="floating-box-header-newload align-items-center"
            style={{ margin: 0, padding: 0 }}
          >
            <span>{toastTitle}</span>
          </div>
          <p
            style={{ fontSize: "100%", fontWeight: 500 }}
            className="text-danger"
          >
            {errorMsg}
          </p>
        </Alert>
      </Toast>

      <SaveDraftWithErrorsModal show={showSaveDraftModal} onHide={() => setOnConfirmFunctionDraftModal(null)} onConfirm={onConfirmFunctionDraftModal}/>
      
     
      <InfoModal
        showModal={showModal}
        setShowModal={setShowModal}
        tabKey={key}
        setKey={setKey}
      />

      <ConfirmationModal
        header="Confirm Reset"
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={resetPage}
      >
        Are you sure you want to choose another input method? You will lose any
        products which you have already entered.
      </ConfirmationModal>

      <PreferentialModal
        onConfirm={() => {nextPage(); setRgrWarningShown(false); setPrefWarningShown(true); }}
        onHide={() =>{setShowPreferentialConfirmation(false);  setPrefWarningShown(false);}}
        show={showPreferentialConfirmation}
      />

      {/* {store.pasteNewLoad[0] === null && !store.isEidr[0] && (
        <OptionDirection nextPage={() => null} />
      )} */}
 {showBulkModal &&(<BulkOperations id="cabieLoads" key="cabieLoads" onBulkClose={()=> setShowBulkModal(false) } itemCount = {itemRows} invoiceNumber = {store.invoicenumber[0]} excelState={spreadsheetHandle?.current.excelState} totalValue={totalValue}/>)}
      <div style={{...(store.pasteNewLoad[0] !== null || store.isEidr[0] ? {display: 'initial'} : {display: 'none'})}}>
        <Container
          fluid
          style={{ borderRadius: "20px", backgroundColor: "#f0f4f9" }}
        >
          <div className="floating-box-header-newload flex-row" style={{paddingTop: '20px'}}>
            <span>Product Information {!!store.clientID[0] && !validateRole({isCustomer: true}) && (<span style={{fontSize: '1.0rem', marginTop: '0.3rem'}}>({store.clientIDname[0]})</span>)}</span>
            <div style={{ flexGrow: 1 }}></div>
            {/* <button
              className="btn d-flex gap-2 align-items-center"
              style={{ color: "#fff", backgroundColor: "#00857d" }}
              onClick={() => setShowConfirmation(true)}
            >
              <span class="bold" style={{fontSize: '11pt'}}>Reselect Input Method</span>
              <span>
                <img src={switchSVG} alt="" />
              </span>
            </button> */}
          </div>
          <GridTotalsPI 
            grossKG={grossMass}
            netKG={netMass}
            value={totalValue}
            itemCount={itemsCount}
            rows={itemRows}
          />
          
          <div style={{...(store.pasteNewLoad[0] === true || store.isEidr[0] ? {display: 'initial'} : {display: 'none'})}} className="load-product-information">
              <Spreadsheet handle={spreadsheetHandle} visible={visible} editable={true}>
              {!store.tadEns[0] && (
                <Spreadsheet.Column
                  key="commoditycode"
                  type="text"
                  minWidth={14}
                  align="center"
                  tooltip={<CommodityCodeInfoTooltip />}
                >
                  HS Code
                </Spreadsheet.Column>
              )}
              <Spreadsheet.Column key="origin" type="text" minWidth={10} align="center">
                Origin
              </Spreadsheet.Column>
              <Spreadsheet.Column key="description" type="text" stretch minWidth={12}>
                Description
              </Spreadsheet.Column>
              <Spreadsheet.Column key="packageCount" type="number" minWidth={10}>
                No. Items
              </Spreadsheet.Column>
              <Spreadsheet.Column
                key="packageType"
                type="text"
                minWidth={13}
                align="center"
                tooltip={<PackageTypeInfoTooltip />}
              >
                Type
              </Spreadsheet.Column>
              <Spreadsheet.Column
                key="grossMass"
                type="number"
                minWidth={15}
                tooltip={<GrossMassInfoTooltip />}
              >
                Gross Mass
              </Spreadsheet.Column>
              <Spreadsheet.Column
                key="netMass"
                type="number"
                minWidth={14}
                tooltip={<NetMassInfoTooltip />}
              >
                Net Mass
              </Spreadsheet.Column>
              <Spreadsheet.Column key="itemValue" type="number" minWidth={12}>
                Value
              </Spreadsheet.Column>
              <Spreadsheet.Column key="quantity2" type="number" minWidth={10} align="right">
                Qty 2
              </Spreadsheet.Column>
              <Spreadsheet.Column key="healthCert" type="text" minWidth={13}>
                Health Cert
              </Spreadsheet.Column>
              <Spreadsheet.Column key="catchCert" type="text" minWidth={14}>
                Catch Cert
              </Spreadsheet.Column>
              <Spreadsheet.Column key="meursing" type="text" minWidth={10}>
                Meursing
              </Spreadsheet.Column>
              <Spreadsheet.Column key="organic" type="text"  default_text="NO" align="center" minWidth={9}>
                Organic
              </Spreadsheet.Column>
              <Spreadsheet.Column key="VAT" type="text" minWidth={9}
              align="center"
              tooltip={<PreferentialTooltip><h6>Valid Options</h6>
              <ul>
                <li>VATZ = Zero Rated</li> 
                <li>VATS = Standard Rated</li>
              </ul></PreferentialTooltip>}
              >
                VAT
              </Spreadsheet.Column>
              <Spreadsheet.Column key="rgr" type="text" minWidth={9}>
                RGR
              </Spreadsheet.Column>

              <Spreadsheet.Column
                key="preferential"
                type="text"
                align="center"
                minWidth={20}
                tooltip={<PreferentialTooltip><h6>Valid Options</h6>
                <ul>
                  <li>Yes</li> 
                  <li>No</li>
                </ul></PreferentialTooltip>}

              >
                Pref
              </Spreadsheet.Column>
              <Spreadsheet.Column key="catchCert2" hidden type="text" minWidth={12}>
                Catch Cert
              </Spreadsheet.Column>
              </Spreadsheet>

              <div class="d-flex py-3 px-3 w-100 justify-content-between">
                <div class="d-flex">
                {showBulkBtn && (<button
                  className="epo-continue-button "
                  type="button"
                  onClick={() => {setShowBulkModal(true)}}
                >
                  Bulk Action
                </button>)}
                </div>
             <div class="d-flex gap-3">
                <button
                  className="epo-continue-button "
                  type="button"
                  onClick={() => {
                    store.isEidr[1](true);
                     validateHsRows(true);                   
                  }}
                >
                  Save as Draft
                </button>

                 <button
                  className="blue-button-newload"
                  type="button"
                  onClick={(event) => {
                    validateHsRows(false);
                  }}
                  disabled={loadStatus === 'CM8 Draft' || disableOrderForm}
                >
                  Order Form
                </button>
                </div>
              </div>
            </div>

          {store.pasteNewLoad[0] === false && (
            <ProductGridContext.Provider value={productContext}>
              <TableEditorAlt validate={validateProductRows} />
            </ProductGridContext.Provider>
          )}
        </Container>
      </div>
    </div>
  );
};

const removeEmptyOrNull = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === "object" && removeEmptyOrNull(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && delete obj[k]) ||
      (typeof obj[k] === "string" && obj[k].trim() === '' && delete obj[k])
  );
  return obj;
};

const nullToBlank = (x) => {
  if (x?.result) {
    return nullToBlank(x.result);
  }

  if (x === null || x === undefined) {
    return "";
  }
  return x;
};

const findById = (arr, id) => {
  const result = arr.find(item => item?.id?.toString() === id?.toString());
  if (result) return result;
  return {};
}

export default ProductInfo;
