import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Alert } from "react-bootstrap";
import {Outlet, useNavigate, Navigate} from "react-router-dom";
// import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import securityReducer from "./securityReducer";

/**
 * @typedef {"LIMITED" | "STANDARD" | "SUPERUSER"} Role
 * @typedef {"OPS" | "CMT" | "DCT" | "FIN" | "TRADER" | "LSP" | "LOGISTICS" | "HAULIER" | "FREIGHT"} Department
 * @typedef {[Department, Role]} DepartmentRolePair
 * @typedef {Department | Array<DepartmentRolePair | Department>} DepartmentProp
 * @typedef {Object} SecurityContextType
 * @property {import("./securityReducer").SecurityState} securityState
 * @property {import("React").ReducerWithoutAction} dispatch
 * @property {(config: SecurityConfiguration) => boolean} validateRole
 * 
 * @typedef {Object} SecurityConfiguration
 * @property {SecurityObject[]} or
 * @property {DepartmentProp} department
 * @property {Role} role
 * @property {boolean} isLoggedIn
 * @property {boolean} isAdmin
 * @property {boolean} isCustomer
 */

/**
 * @typedef {Object} SecurityObject
 * @property {Department} department
 * @property {Role} role
 * @property {boolean} isCustomer
 * @property {boolean} isAdmin
 * @property {boolean} isLoggedIn
 */

const ROLE_MAP = {
  LIMITED: 1,
  STANDARD: 2,
  SUPERUSER: 3,
};

export const SecurityContext = createContext({});

const useQuery = () => {
  const { search } = useLocation();
  
  return useMemo(() => new URLSearchParams(search), [search]);
};

export function SecurityProvider({ children }) {
  const [state, dispatch] = useReducer(securityReducer, { loggedIn: false });
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: "loadSession" });
    setSessionLoaded(true); // force the app to wait until we've loaded the session before rendering
  }, []);

  const query = useQuery();
  const authError = query.get("authError");

  const validateRole = useCallback(
    /**
      * @param {SecurityConfiguration} props
     */
    (props) => {
      if (Object.values(props).every(prop => prop === undefined)) {
        return false;
      }
      const { or, department, role, isLoggedIn, isAdmin, isCustomer } = props;

      if (Array.isArray(or)) {
        for (const secObj of or) {
          if (matchSecurityObject(state, secObj)) {
            return true;
          }
        }
      } else if (
        matchSecurityObject(state, {
          department,
          role,
          isAdmin,
          isLoggedIn,
          isCustomer,
        })
      ) {
        return true;
      }
      return false;
  }, [state]);

  const context = {
    securityState: state,
    dispatch,
    validateRole
  };

  return (
    <SecurityContext.Provider value={context}>
      {sessionLoaded && children}
      {authError && (
        <Alert variant="danger" className="position-fixed bottom-0 end-0 alert-dismissible me-5 ">
          <Alert.Heading>Error Loading Page</Alert.Heading>
          <p>{authError}</p>
          <button type="button" class="btn-close" onClick={() => navigate(location.pathname.split('?')[0], { replace: true })}></button>
        </Alert>
      )}
    </SecurityContext.Provider>
  );
}

/**
 *
 * @param {SecurityConfiguration & {children: import("react").ReactChildren}} props
 */
export function ShowIf({ children, ...rest }) {  
  const { validateRole } = useSecurity();
  if (validateRole(rest)) {
    return children;
  } else {
    return null;
  }
}

/**
 *
 * @param {import("./securityReducer").SecurityState} userdata
 * @param {SecurityObject} matchAgainst
 */
function matchSecurityObject(userdata, matchAgainst) {
  if (matchAgainst.isLoggedIn !== undefined && !!userdata.loggedIn !== !!matchAgainst.isLoggedIn) return false;
  if (matchAgainst.isAdmin !== undefined && !!userdata.admin !== !!matchAgainst.isAdmin) return false;

  if ((process.env.REACT_APP_ENV === "dev" || process.env.NODE_ENV === "test") && userdata.department?.toUpperCase() === "DEV") return true;


  // console.log("matchAgainst", matchAgainst)
  // console.log("userdata", userdata)

  if (matchAgainst.isCustomer !== undefined) {
    const userIsCustomer = ["TRADER", "LOGISTICS", "HAULIER", "FREIGHT","LSP"].includes(userdata.department?.toUpperCase());
    if (userIsCustomer !== matchAgainst.isCustomer) return false;
  }

  if (matchAgainst.department !== undefined) {
    if (matchAgainst.department.toUpperCase?.() !== userdata.department?.toUpperCase()) {
      return false;
    }
  }


  if (matchAgainst.role !== undefined) {
    if (ROLE_MAP[userdata.role.toUpperCase()] < ROLE_MAP[matchAgainst.role.toUpperCase?.()]) {
      return false;
    }
  }

  return true;
}

export function PrivateRoute({ children, ...rest }) {
  const location = useLocation();
  const navigate = useNavigate()

  const { securityState } = useSecurity();

  return(
    //securityState.loggedIn ? <Outlet/> : navigate('/login', { state: { from: location.pathname } })
    securityState.loggedIn ? <Outlet/> : <Navigate to="/login" state={{ from: location.pathname }}/>
  )
  // const location = useLocation();

  // const { securityState } = useSecurity();

  // if (securityState.loggedIn) {
  //   return <Route {...rest}>{children}</Route>;
  // } else {
  //   return (
  //     <Route>
  //       <Navigate to="/login" state={{ from: location.pathname }}/>
  //     </Route>
  //   );
  // }
}

/**
 *
 * @returns {SecurityContextType} securityContext
 */
export const useSecurity = () => {
  return useContext(SecurityContext);
};
