import React, { useEffect, useMemo, useState, useRef } from "react";
import {OverlayTrigger, Overlay, Popover, Modal, Table } from 'react-bootstrap';
import "./DataTable.css"
import { Input, Select } from "../Input/Input";
import { Downloadxls } from './Downloadxls';
import { Link } from "react-router-dom";
import { ExternalLinkAlt, Trash} from "@styled-icons/fa-solid";
import isoDateConverter from '../isoDateConverter';
import downloadIcon from '../../images/download.svg';
import restartSVG from "../../images/restart.svg";

  const DataTable1 = ({ initialData, defaultStyle, headerStyle, container, containerRef, downloadable, hideNavigation, ...rest }) => {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState(null);
    const [filterConfig, setFilterConfig] = useState({});
    const [showPopover, setShowPopover] = useState(false);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const [popoverColumnKey, setPopoverColumnKey] = useState(null);
    const [sortDirections, setSortDirections] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; 
    const [filterActive, setFilterActive] = useState(false);
    const [redirectReason, setRedirectReason] = useState(null);

    const buttonClick = (event, id) => {
      event.preventDefault();
        rest.buttonOnClick(id)
  
    };

    const buttonClickDelete = (event, id, buttonType) => {
      event.preventDefault();
      if (buttonType === 'delete') {
        rest.handleDeleteRow(id)
      }
      if (buttonType === 'restore') {
        rest.handleRestoreRow(id)
      }
      else return

    }

  
     const clearFilters = () => {
      setFilterConfig({});
      setFilterActive(false);
      setCurrentPage(1);
    };
  
    const openPopover = (event, columnKey) => {
      setPopoverTarget(event.target);
      setPopoverColumnKey(columnKey);
      setShowPopover(true);
    };
  
    const closePopover = () => {
      setShowPopover(false);
      setPopoverColumnKey(null);
    };
  
      const requestSort = (key, direction) => {
        let newSortDirections = { ...sortDirections };
        newSortDirections[key] = direction;
        setSortDirections(newSortDirections);
        setData(prev => {
          let sortedData = [...prev];
          sortedData.sort((a, b) => {
            if (a[key] < b[key]) {
              return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
              return direction === 'asc' ? 1 : -1;
            }
            return 0;
          });
          return sortedData;
        });
      };
    
      useEffect(() => {
        setData(initialData || []);
      }, [initialData]);
      
  
      const columns = useMemo(() => {
        if (initialData && initialData.length > 0) {
          const firstDataItem = initialData[0];
          return Object.keys(firstDataItem).map((key) => ({
            headerName: key,
            dataKey: key,
          }));
        }
        return [];
      }, [initialData]);
      
  
      const handleFilter = (key, value) => {
        setFilterConfig(prev => ({ ...prev, [key]: value }));
        setFilterActive(true);
        setCurrentPage(1);
      };
  
      const handleCellChange = (e, rowIndex, columnKey) => {
        const newData = [...data];
        newData[rowIndex][columnKey] = e.target.value;
        setData(newData);
      };
      
      const sortedData = useMemo(() => {
        let sortableData = [...data];
        if (sortConfig !== null) {
          sortableData.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
        }
            Object.keys(filterConfig).forEach(key => {
              sortableData = sortableData.filter(item => {
                const value = item[key];
                const filterValue = filterConfig[key];
                return value?.toLowerCase && typeof value === 'string' && value.toLowerCase().includes(filterValue.toLowerCase());
              });
            });
        return sortableData;
      }, [data, sortConfig, filterConfig]);
  
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, sortedData.length);
      const paginatedData = sortedData.slice(startIndex, endIndex);
      const totalPages = Math.ceil(sortedData.length / itemsPerPage);

      const filteredDownload = () => {
        if (filterActive) {
          setRedirectReason('filerActive')
        }
        else {
          Downloadxls(sortedData)
        }
        return
      }

      const RedirectModal = ({ redirectReason, onHide, onContinue }) => {
        function cancelClick() {
          onHide();
        }
        function onContinue() {
          Downloadxls(sortedData)
          onHide();    
        }


        return (
          <Modal show={!!redirectReason} centered size={"md"} onHide={onHide} animation={false}>
            <Modal.Header>
              <Modal.Title className="d-flex gap-3 align-items-center py-2">
                <div className="warning" /> <span>
                  {/* {redirectReason?.title} */}
                  Filters set
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 py-4">
              {/* {redirectReason?.body} */}
              You currently have one or more filters set and this may impact your download results.
            </Modal.Body>
            <Modal.Footer
              class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
              style={{
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
                <button class="stepper-button yellow" onClick={()=> onContinue()}>
                Continue
              </button>
              <button class="stepper-button" onClick={cancelClick}>
                Cancel
              </button>

            </Modal.Footer>
          </Modal>
        );
      };


      return (
  <>

    <RedirectModal
      redirectReason={redirectReason}
      onHide={() => {
        setRedirectReason(null);
      }}
      onContinue={() => {
        setRedirectReason(null);
      }}
    />

  
        <Table style={{ borderCollapse: 'collapse' }}>
          <thead style={{borderStyle: "hidden"}}>
              <tr>
            {columns.map((column, colIndex) => (
              (column.headerName !== "id" &&   column.headerName !== "button" && column.headerName !== "deleteRestore" &&          
              <th
              key={colIndex}
              onClick={(event) => openPopover(event, column.dataKey)}
              style={{...headerStyle, ...column.style}}
            >
              {column.headerName == "button" ?      
              "VIEW" : column.headerName == "deleteRestore" ? "" : column.headerName}
              {column.headerName == "button" ||  column.headerName == "deleteRestore" ?      
              <></> :                <FilterIcon style={{ width: "1.2em", height: "1.5em", color: '#f00c0c' }} className="green-text"/>}

            </th>
            )  
            ))}
                        {columns.map((column, colIndex) => (
              (column.headerName === "button"  &&     
   
              <th
              key={colIndex}
              onClick={(event) => openPopover(event, column.dataKey)}
              style={{...headerStyle, ...column.style, paddingRight: "10px" , display: "table-cell", textAlign: "right"}}
              className="view-table-header-reports"
            >

             { "VIEW" }
            </th>
            )  
            ))}

          </tr>
          </thead>
          <tbody>
            {paginatedData.map((row, rowIndex) => (
              
             <tr key={rowIndex} style={{ background: 'transparent' }}>
             {columns.map((column, colIndex) => (
                              column.dataKey !== "id" && (
               <td key={`${rowIndex}-${colIndex}`} style={{...defaultStyle, ...column.style}}>

                    {column.dataKey === "button" ? (
                   <div className='d-flex justify-content-end '>
                      <Link
                      className="external-table-link-reports"
                      onClick={(event) => buttonClick(event, row)}
                    >
                      <ExternalLinkAlt 
                      style={{...rest.imageStyle}}
                      />
                    </Link>
                    </div>
                    ) :
                    column.dataKey === "deleteRestore"  ? (
                      rest.superOps ? (
                      row[column.dataKey] === true ? (
                      <div style={{ textAlign: 'center', paddingTop: '', width: '80px', height: '20px' }}>
                        <button
                        className="reset-btn-filters restore-btn"
                        onClick={(event) => buttonClickDelete(event, row, 'restore')} 
                        style={{border:"0px"}}
                        >
                          <img
                            style={{ color: "blue" }}
                            src={restartSVG}
                            width="20"
                            alt="Spinning Arrow"
                          />
                        </button>
                      </div>
                      ) : (
                        <div style={{ textAlign: 'center', paddingTop: '', width: '80px', height: '20px' }}>
                          <Trash 
                            width="1.1rem" 
                            className="invoice-delete-icon
                            
                            
                            " 
                            style={{ cursor: 'pointer', color: 'red' }} 
                            onClick={(event) => buttonClickDelete(event, row, 'delete')}/>
                        </div>
                        )

                       ) : (<></> ) )
                    
                    : (
                      <input 
                        type="text" 
                        value={column.headerName.toLowerCase() === "date" ? isoDateConverter(row[column.dataKey]) : row[column.dataKey]} 
                        {...rest}
                        onChange={e => handleCellChange(e, rowIndex, column.dataKey)}
                        style={{...rest.inputStyle}}
                      />
                    )}
                  </td>
                  )
                ))}
              </tr>
              
            ))}
        </tbody>
    
          <Overlay
              show={showPopover}
              target={popoverTarget}
              placement="right"
              container={containerRef} 
              rootClose={true}
              onHide={closePopover}
            > 
              <Popover style={{ maxWidth: "300px" }}>
                  <div className="py-2">
                    <section className="px-3">
                      <h6 className="text-eoriblue mt-2">Sort</h6>
                      <div className="d-flex justify-content-between gap-2 mb-3">
                          <button onClick={() => requestSort(popoverColumnKey, 'asc')} className='sort-btn'>
                            <span>Ascending</span>
                            <SortUp style={{ width: "0.8rem", height: "0.8rem" }} />
                          </button>
                          <button onClick={() => requestSort(popoverColumnKey, 'desc')} className='sort-btn'>
                            <span>Descending</span>
                            <SortDown style={{ width: "0.8rem", height: "0.8rem" }} />
                          </button>
                        </div>
                        <Input
                          label={<h6 className="text-eoriblue mb-0">Filter By</h6>}
                          type="text"
                          onChange={(event) => {handleFilter(popoverColumnKey, event.target.value);}}
                        />
                      </section>
                  </div>
              </Popover>
      </Overlay>
  </Table>
  <div className="d-flex justify-content-between align-items-center">
  <div className="d-flex pb-3">
  {rest.returnPrev ? (  
    <div >
            <button type="button" class="cancel-button" onClick={rest.returnPrev} style={{ display: "flex", borderRadius: "0.9rem", marginRight:"20px", backgroundColor: "#006c63", color: "#f3f4f6"  }}>
              Back
            </button>
  </div>) : (<div > </div>)}
  {downloadable ? (  
    <div >
        <button className="download-button" style={{ marginRight:"20px"}} onClick={() => filteredDownload()}>
            <img src={downloadIcon} alt="Download" className="download-icon" style={{ width: '22px', height: '22px' }} />
        </button>
  </div>) : (<div > </div>)}
  {hideNavigation && sortedData.length < 10 ? null : (
    <button
      onClick={filterActive && clearFilters}
      className={`clear-button${filterActive ? '' : ' inactive'}`}
      disabled={!filterActive}
    >
      Clear Filters
    </button>
  )}
  </div>

    {hideNavigation && sortedData.length < 10 ? null : (
      <div className="my-4">
          <button
            className="pagination-button mx-1"
            onClick={() => setCurrentPage(1)} 
            disabled={currentPage === 1}
          >
            |&lt;&lt;
          </button>
          <button
            className="pagination-button"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt; 
          </button>
          <span className="pagination-info">
            {currentPage} of {totalPages}
          </span>
          <button
            className="pagination-button mx-1"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
          <button
          className="pagination-button"
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        >
          &gt;&gt;|
        </button>
    </div>) }
  
          
    </div>
  </>
      );
    };
  
    function SortUp(props) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136.957 179.027" fill="currentColor" {...props}>
          <path d="M 15.218 76.726 L 60.87 76.726 C 69.273 76.726 76.087 71 76.087 63.939 C 76.087 56.877 69.273 51.151 60.87 51.151 L 15.645 51.151 C 7.242 51.151 0.428 56.877 0.428 63.939 C 0.428 71 6.801 76.726 15.218 76.726 Z M 15.218 127.877 L 91.304 127.877 C 99.707 127.877 106.522 122.15 106.522 115.089 C 106.522 108.028 99.707 102.301 91.304 102.301 L 15.645 102.301 C 7.242 102.301 0.428 108.028 0.428 115.089 C 0.428 122.15 6.801 127.877 15.218 127.877 Z M 15.218 25.576 L 30.434 25.576 C 38.838 25.576 45.224 19.849 45.224 12.788 C 45.224 5.727 38.41 0 30.434 0 L 15.218 0 C 6.815 0 0 5.727 0 12.788 C 0 19.849 6.801 25.576 15.218 25.576 Z M 121.739 153.452 L 15.645 153.452 C 7.242 153.452 0.428 159.179 0.428 166.24 C 0.428 173.301 7.242 179.027 15.645 179.027 L 121.739 179.027 C 130.141 179.027 136.957 173.301 136.957 166.24 C 136.957 159.179 130.156 153.452 121.739 153.452 Z" />
        </svg>
      );
    }
    
    function SortDown(props) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136.957 179.027" fill="currentColor" {...props}>
          <path d="M 15.218 102.301 L 60.87 102.301 C 69.273 102.301 76.087 108.027 76.087 115.088 C 76.087 122.15 69.273 127.876 60.87 127.876 L 15.645 127.876 C 7.242 127.876 0.428 122.15 0.428 115.088 C 0.428 108.027 6.801 102.301 15.218 102.301 Z M 15.218 51.15 L 91.304 51.15 C 99.707 51.15 106.522 56.877 106.522 63.938 C 106.522 70.999 99.707 76.726 91.304 76.726 L 15.645 76.726 C 7.242 76.726 0.428 70.999 0.428 63.938 C 0.428 56.877 6.801 51.15 15.218 51.15 Z M 15.218 153.451 L 30.434 153.451 C 38.838 153.451 45.224 159.178 45.224 166.239 C 45.224 173.3 38.41 179.027 30.434 179.027 L 15.218 179.027 C 6.815 179.027 0 173.3 0 166.239 C 0 159.178 6.801 153.451 15.218 153.451 Z M 121.739 25.575 L 15.645 25.575 C 7.242 25.575 0.428 19.848 0.428 12.787 C 0.428 5.726 7.242 0 15.645 0 L 121.739 0 C 130.141 0 136.957 5.726 136.957 12.787 C 136.957 19.848 130.156 25.575 121.739 25.575 Z" />
        </svg>
      );
    }
  
    function FilterIcon(props) {
      return (
        <svg viewBox="0 0 24 24" {...props} fill="currentColor"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path></svg>
      );
    }
  export default DataTable1;