import { MinusCircle, PlusCircle } from "@styled-icons/fa-solid";
import { useEffect, useState, useRef, useContext } from "react";
import { Col, Row, Popover, OverlayTrigger, PopoverContent, Collapse } from "react-bootstrap";
import { checkEORIvalid, checkVatValid, getRegistrationType, checkEORIDuplicate } from "../apiCalls";
import { Input, Select } from "../Input/Input";
import { useStepper } from "../Stepper";
import request from "../Epo/assets/request.png"
import { GoogleAnalyticsContext } from "../GoogleAnalytics";
import WarningModal from "./Sign Up/WarningModal";
import { StoreContext } from "../Store";

export default function BasicDetails() {
  const { stepForward, localData,setLocalData, setDataByKey, stepBackward, activeStepKey } = useStepper();

  const [errors, setErrors] = useState({});
  const [fetchingEori, setFetchingEori] = useState(false);
  const [fetchingVat, setFetchingVat] = useState(false);
  const [categoryOpts, setcategoryOpts] = useState([{ id: "trader", data: "Trader" }]);
  const store = useContext(StoreContext);
  const [eoriData, setEoriData] = useState({
    valid: false,
    companyName: "",
    eori: ""
  });
  const [vatData, setVatData] = useState({
    valid: false,
    companyName: "",
    vatNumber: ""
  });

  const [disableLegalNameInput, setDisableLegalNameInput] = useState(true);

  const wlpOptions = [
    { id: "trader", data: "Trader" },
    { id: "lsp", data: "Logistics Service Provider (LSP)" },
    { id: "haulier", data: "Haulier", disabled: true },
    { id: "freight", data: "Freight/Forwarder", disabled: true },
  ];  

  useEffect(() => {
      localData.companyCategory = "trader";
      localData.isFullRegProcess = true;
  },[]);  

  const [formationList, setFormationList] = useState([
    { id: "PVLC", data: "Private Limited Company" },
    { id: "ST", data: "Sole Trader" },
    { id: "LLP", data: "Limited Liability Partnership" },
    { id: "PLC", data: "Public Limited Company" },
  ]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const isInitialized = useRef(false);

  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "basic" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "1. BasicDetails",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  useEffect(() => {
    async function fetchData() {
      const results = await getRegistrationType();
      // console.log("isFullRegProcess: ", results[0].passBack);
      setDataByKey("isFullRegProcess", results[0].passBack);
    }
    fetchData();
  }, []);

  
  useEffect(() => {
    setDataByKey("useFullRegProcess", store.isWLP[0] === true ?  false : localData.isFullRegProcess );
    // console.log("useFullRegProcess:", localData.useFullRegProcess);
  }, [store.isWLP[0]]);

  

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};

    if (!localData.eoriNumber) {
      freshErrors.eoriNumber = "Required";
    }

    if (eoriData.valid) {

      if (localData.vatNumber) {
        if (!vatData.valid) {
          freshErrors.vatNumber = "Invalid"
        }
      }
  
      if (!localData.companyName || localData.companyName === "---") freshErrors.companyName = "Invalid or missing value";
  
      if (!localData.formation) freshErrors.formation = "Required";
      if (!localData.companyCategory) {
        freshErrors.companyCategory = "Company category is required.";
      }

      if(localData.hasDda && !localData.dutyDefermentNumber) freshErrors.dutyDefermentNumber = "Required";
      // else if (localData.companyCategory !== "trader") {
      //   freshErrors.companyCategory = "Sorry, we currently only accept new registrations from Traders.";
      // }  
    }

    if((!localData.gbEntity || localData.gbEntity === "---")) {
      freshErrors.gbEntity = "Invalid or missing value";
    }
  

    setErrors(freshErrors);
    if (Object.keys(freshErrors).length === 0) {
      stepForward();
    }

  };

  const getNameFromEori = async () => {
    // if (!localData.eoriNumber.toUpperCase().startsWith("GB")) {
    //   setErrors((prev) => ({ ...prev, eoriNumber: "Must start with GB, value reverted" }));
    //   setDataByKey('eoriNumber', eoriData.eori);
    //   return;
    // }

    setFetchingEori(true);
    try {
      setErrors((prev) => ({ ...prev, eoriNumber: undefined }));
      const resp = await checkEORIvalid(localData.eoriNumber);
      const [response] = await checkEORIDuplicate(localData.eoriNumber, localData.emailAddress);
      const { registered, duplicate} = response;

      if(duplicate){
        setShowConfirmModal(true);
        setDataByKey('vatNumber', "");
        setDataByKey('gbEntity', "");
        setDataByKey('companyName', "");
      }     
      else if (resp.statusDescr === "In Valid") {
        setErrors((prev) => ({ ...prev, eoriNumber: "Invalid EORI No. value reverted" }));
        setDataByKey('eoriNumber', eoriData.eori);
        setEoriData(prev => ({...prev, valid: true}));
      } else {
        setEoriData({ valid: true, companyName: resp.name, eori: localData.eoriNumber});
        if (!localData.gbEntity) {
          if (resp.name) {
            setErrors((prev) => ({ ...prev, companyName: undefined }));
          }
        }
        // GB is VAT registered - autofill it.
        if (localData.eoriNumber[2] !== "0") {
          setDataByKey("vatNumber", localData.eoriNumber.slice(2, 11));
          setDataByKey('gbEntity', resp.name ?? '');
          setDisableLegalNameInput(resp.name != '' && resp.name != '---');
          setDataByKey('companyName', resp.name ?? '');
          getNameFromVat(localData.eoriNumber);
        }
      }
    } catch {
      setErrors((prev) => ({ ...prev, eoriNumber: "Unable to validate, value reverted" }));
      setDataByKey('eoriNumber', eoriData.eori);
      setEoriData(prev => ({...prev, valid: true}));
    }
    setFetchingEori(false);
  };

  const getNameFromVat = async (vatNumber = localData.eoriNumber) => {
    if (!vatNumber) {
      setErrors((prev) => ({ ...prev, vatNumber: undefined }));
      setVatData({ valid: true, companyName: ""});
      setDataByKey("building", "");
      setDataByKey("street", "");
      setDataByKey("town", "");
      setDataByKey("county", "");
      setDataByKey("postcode", "");
      setDataByKey("country", "");
      return;
    }
    setFetchingVat(true);
    try {
      setErrors((prev) => ({ ...prev, vatNumber: undefined }));
      const resp = await checkVatValid(vatNumber);
      setVatData({ valid: true, companyName: resp.name});
      setDataByKey("building", resp.building);
      setDataByKey("street", resp.street);
      setDataByKey("town", resp.city);
      setDataByKey("county", resp.county);
      setDataByKey("postcode", resp.postcode);
      setDataByKey("country", resp.countryCode);
      if (resp.name) {
        setDataByKey('gbEntity', resp.name);
        setDataByKey('companyName', resp.name);
        setDisableLegalNameInput(resp.name != '' && resp.name != '---');
      }
    } catch {
      setErrors((prev) => ({ ...prev, vatNumber: "Unable to validate, value reverted" }));
      setDataByKey("vatNumber", vatData.vatNumber);
    }
    setFetchingVat(false);
  };

  //EORI Number duplication warning
  const warning = {
    title: "Unable to Register!",
    body: (
      <>
        <p>
           We are having difficulty in registering you, Please call or email our team who will be able to assist you.
        </p>
        <p>
          <div className="bold text-eoriblue text-nowrap ms-2 mt-1">P: +44 (0) 333 012 4819</div>
          <div className="bold text-eoriblue ms-2">E: <a className="bold text-eoriblue" href="mailto:cabiesupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a></div>
        </p>
      </>
    )
  };

  return (

    <form onSubmit={handleNext}>
      {showConfirmModal &&
              <WarningModal              
               redirectReason={warning}
               onHide={() => {
                  setShowConfirmModal(false);
                  return null;
               }}
            />
    }      
      <div>
        <span className="register-step-header">Company Information</span>
        
        <div className="inputs-group register-info">
          <img src={request} width="70" height="70" alt="" />
          <span>
            The details below should be the company which will be interacting with CABIE, and you 
            should have permission from the company before going any further in this sign up process.
          </span>
        </div>
        <Collapse in={Object.values(errors).some((v) => !!v)}>
          <div className="px-3 py-1 mt-2 alert-danger" style={{borderRadius: '10px'}}>Some errors were found, please correct below.</div>
        </Collapse>
        <Row className="pt-4">
          <Col>
            <Input
              label={
                <>
                  EORI No.<span style={{ fontSize: "120%" }}>*</span>
                </>
              }
              error={errors.eoriNumber}
              value={localData.eoriNumber}
              onChange={(e) => {
                setEoriData(prev => ({...prev, valid: false}));
                setDataByKey("eoriNumber", e.target.value)
              }}
              onBlur={() => getNameFromEori()}
              placeholder="e.g. XX123456789012"
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover style={{ minWidth: "15rem" }}>
                      <PopoverContent>
                        <h6>EORI Number</h6>
                        <p>Your EORI number is required here.</p>
                        </PopoverContent>
                      </Popover>
                    }
                  >
                    <div className="important-info"></div>
                </OverlayTrigger>
              }
            />
          </Col>
          <Col>
          <Input
              label={
                <>
                  Legal Name of Entity <span style={{ fontSize: "120%" }}>*</span>
                </>
                }
              disabled={disableLegalNameInput}
              error={errors.gbEntity}
              value={localData.gbEntity}
              onChange={(e) => setDataByKey("gbEntity", e.target.value)}
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover style={{ minWidth: "15rem" }}>
                      <PopoverContent>
                        <h6>Legal Name of Entity</h6>
                        <p>This will automatically be entered based on your EORI/VAT number.</p>
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <div className="info"></div>
                </OverlayTrigger>
              }
            />
          </Col>
        </Row>

        <Row className="pt-2">
        <Col>
            <Input
              label="VAT Number"
              disabled={!eoriData.valid}
              error={errors.vatNumber}
              value={localData.vatNumber}
              onBlur={() => getNameFromVat()}
              onChange={(e) => {
                setVatData(prev => ({...prev, valid: false}));
                setDataByKey("vatNumber", e.target.value)
              }}
              placeholder="e.g. 123456789"
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover style={{ minWidth: "15rem" }}>
                      <PopoverContent>
                        <h6>VAT Number</h6>
                        <p>Your VAT number, you can find this at the top of your VAT registration certificate</p>
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <div className="info"></div>
                </OverlayTrigger>
              }
            />
          </Col>
          <Col>
          <Select
              label={
                <>
                  Company Type <span style={{ fontSize: "120%" }}>*</span>
                </>
              }
              disabled={!eoriData.valid}
              data={localData.formation}
              setData={(data) => setDataByKey("formation", data)}
              options={formationList}
              error={errors.formation}
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover style={{ minWidth: "15rem" }}>
                      <PopoverContent>
                        <h6>Company Formation</h6>
                        <p>Please select the category the best suits your company</p>
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <div className="info"></div>
                </OverlayTrigger>
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
        <Col>
            <Input
              label={
                <>
                  Company Trading Name <span style={{ fontSize: "120%" }}>*</span>
                </>
              }
              disabled={!eoriData.valid || fetchingVat}
              error={errors.companyName}
              value={localData.companyName}
              onChange={(e) => setDataByKey("companyName", e.target.value)}
              placeholder="e.g. Example Inc."
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover style={{ minWidth: "15rem" }}>
                      <PopoverContent>
                        <h6>Company Trading Name</h6>
                        <p>The name your company trades as, this not necessarily the same as what is listed on your company or VAT registraion.</p>
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <div className="info"></div>
                </OverlayTrigger>
              }
            />
          </Col>
          <Col>
            <Input
              label="Company Registration Number"
              disabled={!eoriData.valid}
              value={localData.companyRegNumber}
              error={errors.companyRegNumber}
              onChange={(e) => setDataByKey("companyRegNumber", e.target.value)}
              placeholder="e.g. 12345678"
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}
                  overlay={
                    <Popover style={{ minWidth: "15rem" }}>
                      <PopoverContent>
                        <h6>Company Registration Number</h6>
                        <p>Your registration number from Companies House in the UK or Companies Registration Office (if UK) in the Republic of Ireland</p>
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <div className="info"></div>
                </OverlayTrigger>
              }
            />
          </Col>
        </Row>

        <Row className="pt-4">
       <Col>
       {/* {store.isWLP[0] && (<Select
              label={
                <>
                  Company Category <span style={{ fontSize: "120%" }}>*</span>
                </>
              }
              disabled={!eoriData.valid}
              data={localData.companyCategory}
              setData={(data) => setDataByKey("companyCategory", data)}
              onChange={e => setDataByKey('dutyDefermentNumber', '')}
              options={categoryOpts}
              error={!!errors.companyCategory}
              info={
                <OverlayTrigger
                  placement="right"
                  trigger={["hover", "focus"]}  
                  overlay={
                    <Popover style={{ minWidth: "35rem" }}>
                      <PopoverContent>
                        <>
                        <h6>Company Category</h6>
                        <ol>
                        <p>Please select the category the best suits your company:
                         <br/>
                        <li> <b>Trader</b> = Importer of Record</li>
                        <li> <b>LSP</b> = Submitting on behalf of another trader</li>
                        </p>
                        </ol>
                        </>
                      </PopoverContent>
                    </Popover>
                  }
                >
                  <div className="info"></div>
                </OverlayTrigger>
              }
            />)} */}
          </Col>          
        </Row>

        <div className="d-flex justify-content-start input-error-box mt-0">
          {errors.companyCategory}&nbsp;
        </div>



        {((localData.companyCategory !== 'lsp') && (localData.companyCategory !== '') )  && (
        <Row className="">
          <Col>
        
          <label className="d-flex align-items-center gap-1 mt-1 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!localData.hasDda} onChange={e => {setErrors(""); setDataByKey('hasDda', e.target.checked, setDataByKey('dutyDefermentNumber', ''))}} />
            <span style={{cursor: "pointer"}} >Do you have a duty deferment account number?</span>
          </label>
      
          </Col>

          <Col>
            <Input
              label={
                <>
                  UK DAN Account <span style={{ fontSize: "120%" }}></span>
                </>
                
              }
          value={localData.dutyDefermentNumber}
          onChange={e => setDataByKey('dutyDefermentNumber', e.target.value)}
          disabled={!localData.hasDda}
          error={errors.dutyDefermentNumber}
        />
          </Col>
        </Row>
            )}


        <Row className="py-2">
          <span style={{ color: "#888", fontSize: "0.75rem" }}>
            With your company registration number we are able to ... therefore it is highly recommended you supply this if your company is registered in GB.
            Check if you're established in the UK for customs&nbsp;
            <a target="_blank" href="https://www.gov.uk/guidance/check-if-youre-established-in-the-uk-or-eu-for-customs" rel="noreferrer">here</a>.
          </span>
        </Row>
        

      </div>
      <section className="d-flex justify-content-between mt-3">
        <button type="button" className="blue-button-teams green-btn" onClick={stepBackward}>
          Back
        </button>

        <button type="submit" className="blue-button-teams green-btn" 
        disabled={fetchingEori || fetchingVat || !eoriData.valid 
            || (localData.vatNumber !== "" && !vatData.valid)   
            || (localData.hasDda && (localData.dutyDefermentNumber === undefined)) 
            }>
          Next
        </button>
      </section>
    </form>
  );
}
