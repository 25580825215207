import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, OverlayTrigger, Popover, PopoverContent, Modal } from "react-bootstrap";
import { getReportList, getSingleReport} from "../apiCalls.js";
import { StoreContext } from "../Store";
import DataTable from "./DataTable1";
import excelFile from "../../images/document-search-icon_Green.png"
import copyIcon from "../../images/clipart.png";  

const ReportsList = () => {
    const store = useContext(StoreContext);
    const navigate = useNavigate()
    const inputStyle = { width: '100%', boxSizing: 'border-box', border: 'none', backgroundColor: 'transparent' }
    // Default Header Styles
   const headerStyle = { fontSize: '12px' };
    // Default Row Styles
   const defaultStyle = { fontSize: '14px', width: '2rem'  };
   const buttonStyle = {backgroundColor: 'transparent', border: 'none', height:'4rem', width: '4rem'}
   const imageStyle = { height:'1rem', width: '1rem', marginRight:'8px' };
    // Override individual row styles

    const storeReports = (data) => {
        store.reportOptions[1](data)
    }
  
    const handleSubmit = (event) => {
      console.log('event ', event.name)
      store.reportSelected[1](event.id)
      const reportName = event.name;
      getSingleReport('report', event.id).then((data) => {  console.log('data dddd ,', data);   store.reportParams[1](data) });

        console.log('event heee ', store.reportParams[0])
        navigate(`/ReportsAdmin/${reportName}`)
  
    };

    useEffect(() => {
        console.log('useeffect')
        async function fetchData() {
          try {
            getReportList().then((data) => {    storeReports(data) });

          } catch (err) {
            console.log(err);
          }
        }
        fetchData();
      }, []);


      console.log('reportOptions ', store.reportOptions[0])


    return (
        <>

        <Container className="full-width-container">
          <section className='floating-box' style={{minWidth: '800px'}}>
            <div className="d-flex flex-row justify-content-between">
                <div  className="floating-box-header-general">
                  <span>Reports</span>
                </div>
                </div>
                
                <div className='px-3 pt-4' >

                  <DataTable
                    // columns={columns}
                    // containerRef={containerRef} 
                    initialData={store.reportOptions[0]} 
                    disabled={true}
                    defaultStyle={defaultStyle}
                    headerStyle={headerStyle}
                    sortable={true}
                    filterable={true}
                    inputStyle={inputStyle}
                    rootClose={true}
                    downloadable={false}
                    // buttonImage={copyIcon}
                    buttonDisabled={false}
                    buttonStyle={buttonStyle}
                    imageStyle={imageStyle}
                    buttonOnClick={handleSubmit}
                    
                    // returnPrev={() => history.push('/ReportsAdmin')}
                    />

                </div>
          </section>
        </Container>

             </>
) 
  };


  export default ReportsList;