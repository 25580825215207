import { forwardRef, useContext, useCallback, useEffect } from "react"
import { CallbackContext } from './Spreadsheet'
const Keyboard = forwardRef(({children}, ref) => {
    const { startEditing, selection, setSelection,
        isEditing,
        stopEditing,
        clearSelection,
        excelState,
        excelDispatch,
        editable,
        handlePaste,
        setHandlePaste,
        getSelectionAsText } = useContext(CallbackContext);

        
        const handleCopy = useCallback(
            (event) => {
              if (document.activeElement !== ref.current) return;
              event.preventDefault();
        
              const text = getSelectionAsText();
              event.clipboardData.setData("text/plain", text);
            },
            [getSelectionAsText]
          );
        
          const handleCut = useCallback(
            (event) => {
              if (!editable) return;
              if (document.activeElement !== ref.current) return;
              event.preventDefault();
        
              const text = getSelectionAsText();
              clearSelection();
              event.clipboardData.setData("text/plain", text);
            },
            [getSelectionAsText, clearSelection]
          );

    useEffect(() => {
        setHandlePaste(() => 
        (event) => {
            if (!editable) return;
            let text = "";
            if (event.type === "paste") {
            if (document.activeElement !== ref.current) return;
            event.preventDefault();
            text = event.clipboardData.getData("text");
            } else if (event.type === "click-paste") {
            text = event.text;
            }
            const starting_x = Math.min(
            selection.root.col_idx,
            selection.bounding.col_idx
            );
            const starting_y = Math.min(
            selection.root.row_idx,
            selection.bounding.row_idx
            );
    
            // If the user is pasting data containing tab characters, see if it matches an Excel selection format
            if (text.includes("\t") || text.includes("\n" ) || text.includes(",")) {
              const matrix = text
                .replaceAll("\r", "")
                .split("\n")
                .map(row => 
                  row.split("\t").map(cell => cell.replace(/,/g, ''))
                );
    
            if (
                matrix[matrix.length - 1].length === 1 &&
                matrix[matrix.length - 1][0] === ""
            ) {
                matrix.pop();
            }
            const matrix_height = matrix.length;
    
            // validate paste matrix - ! MUST BE A 2D ARRAY WITH NO HOLES
            let matrix_width;
            for (let j = 0; j < matrix_height; j++) {
                if (matrix_width === undefined) {
                matrix_width = matrix[j].length;
                } else if (matrix_width !== matrix[j].length) {
                console.error(
                    "I can't paste this, the rows are different lengths!",
                    matrix
                );
                return;
                }
            }
    
            excelDispatch({
                type: "cells",
                location: {
                x: starting_x,
                y: starting_y,
                width: matrix_width,
                height: matrix_height,
                },
                detail: matrix,
            });
    
            setSelection((prev) => ({
                root: { ...prev.root, col_idx: starting_x, row_idx: starting_y },
                bounding: {
                ...prev.bounding,
                col_idx: starting_x + matrix_width - 1,
                row_idx: starting_y + matrix_height - 1,
                },
            }));
            } else {
            const size_x =
                Math.abs(selection.root.col_idx - selection.bounding.col_idx) + 1;
            const size_y =
                Math.abs(selection.root.row_idx - selection.bounding.row_idx) + 1;
            if (size_x > 1 || size_y > 1) {
                excelDispatch({
                type: "cells",
                location: {
                    x: starting_x,
                    y: starting_y,
                    width: size_x,
                    height: size_y,
                },
                detail: text,
                });
            } else {
                excelDispatch({
                type: "cell",
                location: { x: starting_x, y: starting_y },
                detail: { text: text },
                });
            }
            }
        }
        
        );
    }, [selection.root, selection.bounding])
    /** Binds copying/pasting events to document */
  useEffect(() => {
    document.addEventListener("copy", handleCopy);
    document.addEventListener("cut", handleCut);
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("cut", handleCut);
      document.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste, handleCopy, handleCut]);



    const handleKeyDown = useCallback(
        (event) => {
          if (isEditing) {
            let updateSelection = false;
            switch (event.key) {
              case "Escape":
                stopEditing(false);
                updateSelection = true;
                break;
              case "Enter":
                if (editable) {
                  stopEditing(true);
                  if (selection.root.row_idx + 1 === excelState.rows.length) {
                    excelDispatch({ type: "new-row" });
                  }
                  setSelection((prev) => ({
                    ...prev,
                    root: {
                      ...prev.root,
                      row_idx: prev.root.row_idx +1,
                    },
                  }));
                }
                updateSelection = true;
                break;
              case "Tab":
                event.preventDefault();
                stopEditing(true);
                if (event.shiftKey) {
                  if (selection.root.col_idx === 0) {
                    if (selection.root.row_idx !== 0) {
                      setSelection((prev) => ({
                        ...prev,
                        root: {
                          ...prev.root,
                          col_idx: excelState.config.length - 1,
                          row_idx: prev.root.row_idx - 1,
                        },
                      }));
                    }
                  } else {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, col_idx: prev.root.col_idx - 1 },
                    }));
                  }
                } else {
                  if (selection.root.col_idx === excelState.config.length - 1) {
                    if (selection.root.row_idx + 1 === excelState.rows.length) {
                      excelDispatch({ type: "new-row" });
                    }
                    setSelection((prev) => ({
                      ...prev,
                      root: {
                        ...prev.root,
                        col_idx: 0,
                        row_idx: prev.root.row_idx + 1
                      },
                    }));
                  } else {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, col_idx: prev.root.col_idx + 1 },
                    }));
                  }
                }
                updateSelection = true;
                break;
              default:
                break;
            }
    
            if (updateSelection)
              setSelection((prev) => ({ ...prev, bounding: { ...prev.root } }));
          } else {
            if (event.key.length === 1 && !event.ctrlKey) {
              startEditing(true);
              return;
            }
            let shrinkSelection = false;
            switch (event.key) {
              case "z":
              case "Z":
                if (editable && event.ctrlKey) {
                  excelDispatch({ type: "undo" });
                }
                break;
              case "Home":
                event.preventDefault();
                if (event.shiftKey) {
                  setSelection((prev) => ({
                    ...prev,
                    bounding: { ...prev.bounding, col_idx: 0 },
                  }));
                } else {
                  shrinkSelection = true;
                  setSelection((prev) => ({
                    ...prev,
                    root: { ...prev.root, col_idx: 0 },
                  }));
                }
                break;
              case "End":
                event.preventDefault();
                if (event.shiftKey) {
                  setSelection((prev) => ({
                    ...prev,
                    bounding: {
                      ...prev.bounding,
                      col_idx: excelState.config.length - 1,
                    },
                  }));
                } else {
                  shrinkSelection = true;
                  setSelection((prev) => ({
                    ...prev,
                    root: { ...prev.root, col_idx: excelState.config.length - 1 },
                  }));
                }
                break;
              case "F2":
                startEditing();
                break;
              case "Tab":
                event.preventDefault();
                shrinkSelection = true;
                if (event.shiftKey) {
                  if (selection.root.col_idx === 0) {
                    if (selection.root.row_idx !== 0) {
                      setSelection((prev) => ({
                        ...prev,
                        root: {
                          ...prev.root,
                          col_idx: excelState.config.length - 1,
                          row_idx: prev.root.row_idx - 1,
                        },
                      }));
                    }
                  } else {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, col_idx: prev.root.col_idx - 1 },
                    }));
                  }
                } else {
                  if (selection.root.col_idx === excelState.config.length - 1) {
                    if (selection.root.row_idx + 1 === excelState.rows.length) {
                      excelDispatch({ type: "new-row" });
                    }
    
                    setSelection((prev) => ({
                      ...prev,
                      root: {
                        ...prev.root,
                        col_idx: 0,
                        row_idx: prev.root.row_idx + 1,
                      },
                    }));
                  } else {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, col_idx: prev.root.col_idx + 1 },
                    }));
                  }
                }
    
                break;
              case "Enter":
                event.preventDefault();
                if (!editable) break;
                shrinkSelection = true;
                if (selection.root.row_idx + 1 === excelState.rows.length) {
                  excelDispatch({ type: "new-row" });
                }
                setSelection((prev) => ({
                  ...prev,
                  root: {
                    ...prev.root,
                    row_idx: prev.root.row_idx +1,
                  },
                }));
    
                break
              case "ArrowRight":
                event.preventDefault();
                if (event.shiftKey) {
                  // extend selection, leave root alone
                  if (selection.bounding.col_idx !== excelState.config.length - 1) {
                    setSelection((prev) => ({
                      ...prev,
                      bounding: {
                        ...prev.bounding,
                        col_idx: prev.bounding.col_idx + 1,
                      },
                    }));
                  }
                } else {
                  shrinkSelection = true;
                  if (selection.root.col_idx !== excelState.config.length - 1) {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, col_idx: prev.root.col_idx + 1 },
                    }));
                  }
                }
                break;
              case "ArrowLeft":
                event.preventDefault();
                if (event.shiftKey) {
                  // extend selection, leave root alone
                  if (selection.bounding.col_idx !== 0) {
                    setSelection((prev) => ({
                      ...prev,
                      bounding: {
                        ...prev.bounding,
                        col_idx: prev.bounding.col_idx - 1,
                      },
                    }));
                  }
                } else {
                  shrinkSelection = true;
                  if (selection.root.col_idx !== 0) {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, col_idx: prev.root.col_idx - 1 },
                    }));
                  }
                }
                break;
              case "ArrowUp":
                event.preventDefault();
                if (event.shiftKey) {
                  // extend selection, leave root alone
                  if (selection.bounding.row_idx !== 0) {
                    setSelection((prev) => ({
                      ...prev,
                      bounding: {
                        ...prev.bounding,
                        row_idx: prev.bounding.row_idx - 1,
                      },
                    }));
                  }
                } else {
                  shrinkSelection = true;
                  if (selection.root.row_idx !== 0) {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, row_idx: prev.root.row_idx - 1 },
                    }));
                  }
                }
                break;
              case "ArrowDown":
                event.preventDefault();
                if (event.shiftKey) {
                  // extend selection, leave root alone
                  if (selection.bounding.row_idx !== excelState.rows.length - 1) {
                    setSelection((prev) => ({
                      ...prev,
                      bounding: {
                        ...prev.bounding,
                        row_idx: prev.bounding.row_idx + 1,
                      },
                    }));
                  } else {
                    excelDispatch({ type: "new-row" });
                    setSelection((prev) => ({
                      ...prev,
                      bounding: {
                        ...prev.bounding,
                        row_idx: prev.bounding.row_idx + 1,
                      },
                    }));
                  }
                } else {
                  shrinkSelection = true;
                  if (selection.root.row_idx !== excelState.rows.length - 1) {
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, row_idx: prev.root.row_idx + 1 },
                    }));
                  } else {
                    excelDispatch({ type: "new-row" });
                    setSelection((prev) => ({
                      ...prev,
                      root: { ...prev.root, row_idx: prev.root.row_idx + 1 },
                    }));
                  }
                }
                break;
              case "Delete":
              case "Backspace":
                event.preventDefault();
                if (!editable) break;
                clearSelection();
                break;
              default:
                break;
            }
    
            if (shrinkSelection) {
              setSelection((prev) => ({ ...prev, bounding: { ...prev.root } }));
            }
          }
        },
        [
          selection,
          isEditing,
          startEditing,
          stopEditing,
          clearSelection,
          excelState.rows,
          excelState.config,
          excelDispatch,
        ]
      );


    return <div
    style={{
      boxShadow: "0 0 4px 1px rgba(0 0 0 / 20%)",
      borderRadius: "20px"
    }}
    className="d-flex w-100 commodity-table"
    ref={ref}
    tabIndex="0"
    onKeyDown={handleKeyDown}
    onDoubleClick={() => startEditing()}
  >
    {children}
    </div>
})

export default Keyboard